import "./footer.scss";
import logo from "../../assets/logos/rocam-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faMapLocation,
  faPaperPlane,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="footer-top">
        <div className="con">
          <div className="left">
            <img className="logo" src={logo} alt="ROCAM logo" />
            <p>
              ROCAM Schools is aimed at encouraging and empowering young
              Cameroonians to engage in tech-related fields.We work together
              with the computer departments of schools (principally secondary
              schools), personal workshops with students, computer science
              instructors, and other instructors with programs not directly
              related to tech.
            </p>
            <div className="social-icons">
              <a
                href="https://www.facebook.com/groups/2574991899294347"
                className="icon"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href="/https://twitter.com/7devC?s=09" className="icon">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                href="https://www.linkedin.com/company/sevendevcommunity/"
                className="icon"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </div>
          </div>
          <div className="right">
            <div className="sub">
              <h6>Useful links</h6>
              <nav>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About ROCAM</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact us</Link>
                  </li>
                  <li>
                    <Link to="/schools">Schools involved</Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="sub">
              <h6>Get in touch</h6>
              <div className="contact">
                <div className="icon">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <p>+237 690 27 99 29</p>
              </div>
              <div className="contact">
                <div className="icon">
                  <FontAwesomeIcon icon={faPaperPlane} />
                </div>
                <p>info@sevendevcommunity.org</p>
              </div>
              <div className="contact">
                <div className="icon">
                  <FontAwesomeIcon icon={faMapLocation} />
                </div>
                <p>Bali, Douala Cameroon</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="con">
          <p>
            &copy; {new Date().getFullYear()} <span>ROCAM Schools</span> All right Reserved
          </p>
          {/* <a href="/">Privacy Policy</a> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
