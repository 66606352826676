import school1 from "../../../assets/images/school-1.png";
import school2 from "../../../assets/images/school-2.png";
import school3 from "../../../assets/images/school-3.png";

const schools = [
    {
        image: school1,
        name: "Seven Advanced Academy",
    },
    {
        image: school2,
        name: "Seven International University",
    },
    {
        image: school3,
        name: "International School Of Business",
    },
    {
        image: school1,
        name: "Seven Advanced Academy",
    },
    {
        image: school2,
        name: "Seven International University",
    },
    {
        image: school3,
        name: "International School Of Business",
    },
    {
        image: school1,
        name: "Seven Advanced Academy",
    },
    {
        image: school2,
        name: "Seven International University",
    },
    {
        image: school3,
        name: "International School Of Business",
    },
];

export { schools }