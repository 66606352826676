import "./jumbotron.scss";
import handsIcon from "../../assets/icons/hands-icon-circle.png";

const Jumbotron = () => {
  return (
    <div className="jumbotron">
      <div className="sub">
        <div className="con">
          <h1>Become a partner</h1>
          <p>
            We are open to partner and collaborate with you to create a change
            and make every young Cameroonian dream again. We aare ready to hear
            your amazing ideas and thoughts.
          </p>
          <button>
            Become a partner <img src={handsIcon} alt="handshake icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Jumbotron;
