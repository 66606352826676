import iconOrangeHeart from "../../../assets/icons/orange-hat.svg";
import ericPicture from "../../../assets/members/eric.png";

const aboutCardInfo = [
  {
    icon: <img src={iconOrangeHeart} alt="" />,
    title: "mission",
    text: "We are engeaged to Inspire, encourage and motivate students to engage and use tech in their domains and studies in order to adapt to the technological age",
  },
  {
    icon: <img src={iconOrangeHeart} alt="" />,
    title: "vision",
    text: "Create a platform where every student can learn the fundamental tech skills to face the rapidly changing and evolution of technology in all domains",
  },
  {
    icon: <img src={iconOrangeHeart} alt="" />,
    title: "purpose",
    text: "With the repid and recent technological advancements, we are working hand in hand with students, instructors and schools, giving them an opportunity to learn, share and fulfil their dreams",
  },
];

const teamMembers = [
  {
    image: ericPicture,
    name: "Yunwen Eric",
    description:
      "Full stack web and mobile developer at Seven GPS, Community Lead",
    social: [
      {
        name: "facebook",
        link: "https://facebook.com",
      },
      {
        name: "twitter",
        link: "https://facebook.com",
      },
      {
        name: "instagram",
        link: "https://facebook.com",
      },
    ],
  },
  {
    image: ericPicture,
    name: "Asong Flovet",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudinsodales sit donec ut. arcu mauris pretium.",
    social: [
      {
        name: "facebook",
        link: "https://facebook.com",
      },
      {
        name: "instagram",
        link: "https://facebook.com",
      },
    ],
  },
  {
    image: ericPicture,
    name: "Amir Tchakonte",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudinsodales sit donec ut. arcu mauris pretium.",
    social: [
      {
        name: "twitter",
        link: "https://facebook.com",
      },
      {
        name: "instagram",
        link: "https://facebook.com",
      },
    ],
  },
  {
    image: ericPicture,
    name: "David Harrison",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudinsodales sit donec ut. arcu mauris pretium.",
    social: [
      {
        name: "facebook",
        link: "https://facebook.com",
      },
      {
        name: "twitter",
        link: "https://facebook.com",
      },
    ],
  },
];

export { aboutCardInfo, teamMembers };
