import news1 from "../../../assets/images/news-1.png";
import news2 from "../../../assets/images/news-2.png";
import news3 from "../../../assets/images/news-3.png";

const news = [
  {
    image: news1,
    title: "Seven Advanced Academy",
    text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deserunt veritatis possimus, ipsum nam facilis aliquid quaerat odio omnis vitae totam molestias voluptatibus voluptas facere corrupti in amet necessitatibus explicabo rerum.",
  },
  {
    image: news2,
    title: "Seven International University",
    text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deserunt veritatis possimus, ipsum nam facilis aliquid quaerat odio omnis vitae totam molestias voluptatibus voluptas facere corrupti in amet necessitatibus explicabo rerum.",
  },
  {
    image: news3,
    title: "International School Of Business",
    text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deserunt veritatis possimus, ipsum nam facilis aliquid quaerat odio omnis vitae totam molestias voluptatibus voluptas facere corrupti in amet necessitatibus explicabo rerum.",
  },
  {
    image: news1,
    title: "Seven Advanced Academy",
    text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deserunt veritatis possimus, ipsum nam facilis aliquid quaerat odio omnis vitae totam molestias voluptatibus voluptas facere corrupti in amet necessitatibus explicabo rerum.",
  },
  {
    image: news2,
    title: "Seven International University",
    text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deserunt veritatis possimus, ipsum nam facilis aliquid quaerat odio omnis vitae totam molestias voluptatibus voluptas facere corrupti in amet necessitatibus explicabo rerum.",
  },
  {
    image: news3,
    title: "International School Of Business",
    text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deserunt veritatis possimus, ipsum nam facilis aliquid quaerat odio omnis vitae totam molestias voluptatibus voluptas facere corrupti in amet necessitatibus explicabo rerum.",
  },
  {
    image: news1,
    title: "Seven Advanced Academy",
    text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deserunt veritatis possimus, ipsum nam facilis aliquid quaerat odio omnis vitae totam molestias voluptatibus voluptas facere corrupti in amet necessitatibus explicabo rerum.",
  },
  {
    image: news2,
    title: "Seven International University",
    text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deserunt veritatis possimus, ipsum nam facilis aliquid quaerat odio omnis vitae totam molestias voluptatibus voluptas facere corrupti in amet necessitatibus explicabo rerum.",
  },
  {
    image: news3,
    title: "International School Of Business",
    text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deserunt veritatis possimus, ipsum nam facilis aliquid quaerat odio omnis vitae totam molestias voluptatibus voluptas facere corrupti in amet necessitatibus explicabo rerum.",
  },
  {
    image: news1,
    title: "Seven Advanced Academy",
    text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deserunt veritatis possimus, ipsum nam facilis aliquid quaerat odio omnis vitae totam molestias voluptatibus voluptas facere corrupti in amet necessitatibus explicabo rerum.",
  },
  {
    image: news2,
    title: "Seven International University",
    text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deserunt veritatis possimus, ipsum nam facilis aliquid quaerat odio omnis vitae totam molestias voluptatibus voluptas facere corrupti in amet necessitatibus explicabo rerum.",
  },
  {
    image: news3,
    title: "International School Of Business",
    text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deserunt veritatis possimus, ipsum nam facilis aliquid quaerat odio omnis vitae totam molestias voluptatibus voluptas facere corrupti in amet necessitatibus explicabo rerum.",
  },
];

export { news };
