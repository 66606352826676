import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import AdminHome from "./pages/admin/Home/AdminHome";
import Lessons from "./pages/admin/Lessons/Lessons";
import NewsPage from "./pages/admin/News/News";
import About from "./pages/website/About/About";
import Login from "./pages/website/Auth/login/Login";
import Home from "./pages/website/Home/Home";
import News from "./pages/website/News/News";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AddNews from "./pages/admin/News/AddNews";
import AddQuiz from "./pages/admin/Quizz/AddQuiz";
import Quizes from "./pages/admin/Quizz/Quizes";
import AddLesson from "./pages/admin/Lessons/AddLesson";
import Schools from "./pages/website/schools/Schools";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#f79623",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<About />} path="/about" />
          <Route element={<News />} path="/news" />
          <Route element={<News />} path="/add-blog" />
          <Route element={<Schools />} path="/schools" />

          {/* <Route element={<Login />} path="/login" /> */}
          {/* <Route element={<AdminHome />} path="/admin" />
          <Route element={<NewsPage />} path="/admin/news" />
          <Route element={<Lessons />} path="/admin/lessons" />
          <Route element={<AddNews />} path="/admin/add-news" />
          <Route element={<AddQuiz />} path="/admin/add-quiz" />
          <Route element={<AddLesson />} path="/admin/add-lesson" />
          <Route element={<Quizes />} path="/admin/quizes" /> */}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
